<template>
  <NuxtLink v-slot="slotProps" :to="localeRoute(to)" v-bind="$attrs">
    <slot v-bind="slotProps" />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
const localeRoute = useLocaleRoute()

defineProps({
  to: {
    type: [Object, String] as PropType<RouteLocationRaw>,
    required: true,
  },
})
</script>
